import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ProjectIntro from "../a_sections/ProjectIntro";
import SanityImage from "gatsby-plugin-sanity-image";
// import ProjectIndex from "../a_sections/ProjectIndex";
// import { motion, AnimatePresence } from "framer-motion";
// import { GatsbyImage } from "gatsby-plugin-image";

export default function Header({
  selectedProject,
  navOn,
  isMobile,
  selectedRole,
  pageContext,
  handleProjectChange,
  handleMouseOutNav,
  projectClick,
  navDuration,
  navEase,
  projectState,
  windowSize,
}) {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allSanityProject(sort: { orderRank: ASC }) {
        nodes {
          projectName
          roleTagArray {
            name
            id
          }
          press {
            url
            name
          }
          heroImageDarken
          heroImage {
            image {
              ...Image
              asset {
                gatsbyImageData(formats: NO_CHANGE)
                width
                metadata {
                  lqip
                }
              }
            }
          }

          description
          clientTag {
            name
          }
          roleTagArray {
            name
          }
          blurb
          awards {
            url
            name
          }
          slug {
            current
          }
          _id
          mediumTagArray {
            name
          }
          madeAt
        }
      }
    }
  `);

  const s = data.allSanityProject.nodes;

  const roleFilteredProjects = s.filter((node) =>
    selectedRole === "All"
      ? s
      : node.roleTagArray.some((role) => role.name === selectedRole)
  );

  const headerData = navOn ? roleFilteredProjects : s;

  return (
    <>
      <section>
        <div
          className=" absolute h-[0px] w-[0px] overflow-hidden bg-red-500"
          id="imageStack"
        >
          {s.map((s, index) => (
            <>
              {s.heroImage && (
                <SanityImage
                  {...s.heroImage.image}
                  width={windowSize}
                  config={{ quality: 100 }}
                  alt={""}
                />
              )}
            </>
          ))}
        </div>
        {headerData.map((s, index) => (
          <>
            {s.slug.current === selectedProject || isMobile & navOn ? (
              <ProjectIntro
                projectState={projectState}
                windowSize={windowSize}
                navEase={navEase}
                navDuration={navDuration}
                heroImageDarken={s.heroImageDarken}
                isMobile={isMobile}
                madeAt={s.madeAt}
                key={index}
                slug={s.slug.current}
                navOn={navOn}
                name={s.projectName}
                client={s.clientTag ? s.clientTag.name : ""}
                blurb={s.blurb}
                desc={s.description}
                heroImage={s.heroImage ? { ...s.heroImage.image } : false}
                awards={s.awards}
                press={s.press}
                roles={s.roleTagArray}
                mediums={s.mediumTagArray}
                handleProjectChange={handleProjectChange}
                projectClick={projectClick}
                handleMouseOutNav={handleMouseOutNav}
              ></ProjectIntro>
            ) : null}
          </>
        ))}
      </section>
    </>
  );
}
