import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "gatsby";
import IntroDescription from "../c_fragment/IntroDescription";

import { motion } from "framer-motion";
import SanityImage from "gatsby-plugin-sanity-image";

export default function ProjectIntro(props) {
  const animDuration = 0.5;
  const animDelay = 0.125;
  const animEase = "easeOut";
  const projectState = props.projectState;

  var inputElement = useRef();

  const [elementHeight, setelementHeight] = useState({ height: "100vh" });

  function useElementHeight() {
    function elementMatch(ref) {
      var elHeight = ref.current.getBoundingClientRect();
      return elHeight;
    }

    useEffect(() => {
      setelementHeight(elementMatch(inputElement));

      const handleElement = () => {
        setelementHeight(elementMatch(inputElement));
      };
      window.addEventListener("resize", handleElement);
    }, [props.navOn]);

    return elementHeight;
  }

  const elHeight = useElementHeight();

  const imageHeight = elHeight.height;

  var imageDimensions =
    projectState === "mobileHomeInitial"
      ? ["0vh", "100vw"]
      : projectState === "mobileHomeRoleSelected"
      ? ["30vh", "100vw"]
      : projectState === "mobileProjectSelected"
      ? ["70vh", "100vw"]
      : projectState === "HomeInitial"
      ? ["75vh", "100vw"]
      : projectState === "HomeRoleSelected"
      ? ["75vh", "100vw"]
      : projectState === "HomeProjectHover" || projectState === "HomeProjectOff"
      ? ["75vh", "100vw"]
      : projectState === "ProjectNavOff"
      ? [imageHeight, "100vw"]
      : projectState === "ProjectNavOn"
      ? [imageHeight, "100vw"]
      : undefined;

  return (
    <main
      className="flex flex-wrap"
      style={
        {
          // backgroundColor: "blue",
        }
      }
    >
      {props.projectState === "mobileHomeRoleSelected" ? (
        <Link
          className={`absolute z-30 h-[30vh] w-full`}
          to={`/projects/${props.slug}`}
          value={props.slug}
          onClick={props.projectClick}
        ></Link>
      ) : null}
      {/* 
      GRID CONTAINER
      */}
      <div
        id="gridContainer"
        className={`grid w-screen`}
        style={
          {
            // backgroundColor: "green",
          }
        }
      >
        {/* BACKGROUND CONTAINER */}

        <section
          id="backgroundGrid"
          className=" -z-20 col-start-1 row-start-1 w-full"
          // style={{ backgroundColor: "green" }}
        >
          {/* TOP SECTION */}
          <section
            // style={{ backgroundColor: "black" }}
            id="topBackground"
            className="ml-auto h-[0vh] w-full md:h-[25vh] md:w-[--fifty_percentPlusSpacer]"
          ></section>

          {/* BOT SECTION */}

          <div
            className={`relative overflow-hidden`}
            style={
              {
                // backgroundColor: "black",
                // height: "100%",
              }
            }
          >
            <div
              className={`absolute right-0 w-[50vw] overflow-hidden opacity-60`}
              style={{
                // backgroundColor: "purple",
                backgroundImage: `url(${props.heroImage.asset.metadata.lqip})`,
                backgroundRepeat: "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: imageDimensions[0],
                width: imageDimensions[1],
              }}
            ></div>
            <motion.div
              style={{
                backgroundColor: "black",
                // height: "100%",
              }}
              initial={{ opacity: 0, filter: "blur(10px)" }}
              animate={{ opacity: 1, filter: "blur(0px)" }}
              exit={{ opacity: 0, filter: "blur(10px)" }}
              transition={{
                delay: 0,
                duration: animDuration - 0.5,
                ease: animEase,
                opacity: { duration: animDuration },
              }}
            >
              <motion.div
                style={{
                  backgroundColor: "black",
                  height: imageDimensions[0],
                }}
                initial={{
                  width: props.isMobile
                    ? "100%"
                    : props.navOn
                    ? "var(--fifty_percentPlusSpacer)"
                    : "100%",
                }}
                animate={{
                  width: props.isMobile
                    ? "100%"
                    : props.navOn
                    ? "var(--fifty_percentPlusSpacer)"
                    : "100%",
                }}
                transition={{
                  duration: props.navDuration,
                  ease: props.navEase,
                }}
                className={`ml-auto overflow-hidden md:opacity-80`}
              >
                <SanityImage
                  className={`h-full w-full object-cover 
                  
                  ${
                    projectState === "mobileProjectSelected"
                      ? props.heroImageDarken
                        ? "opacity-40 md:opacity-60"
                        : "opacity-60 md:opacity-80"
                      : "opacity-60 md:opacity-80"
                  }
                  `}
                  {...props.heroImage}
                  width={props.windowSize}
                  config={{ quality: 100 }}
                  alt={""}
                  loading={"eager"}
                />
              </motion.div>
            </motion.div>
            <div
              id="gradientBackgroundOverlay"
              className="invisible absolute top-0 opacity-60 transition-colors md:visible"
              style={{
                background: `linear-gradient( var(--overlayGradient) 0%, transparent 30%, 
                ${
                  projectState === "ProjectNavOff" ||
                  projectState === "ProjectNavOn"
                    ? "transparent 60%"
                    : "transparent 100%"
                }, var(--overlayGradient) 90%) `,

                mixBlendMode: "multiply",
                height: imageDimensions[0],
                width: imageDimensions[1],
              }}
            ></div>
          </div>
        </section>

        {/* FOREGROUND CONTAINER */}

        <motion.section
          key="foreground"
          id="foregroundGrid"
          className="z-20 col-start-1 row-start-1 my-auto ml-auto w-full md:my-0 md:w-[--fifty_percentPlusSpacer]"
          // style={{ backgroundColor: "orange", opacity: "1" }}
        >
          {/* TOP SECTION */}
          <motion.section
            // style={{ backgroundColor: "pink", opacity: "1" }}
            id="topForeground"
            className="ml-auto flex h-max w-full px-[--spacer_base_global2] md:h-[25vh]"
          >
            {/* TITLE TITLE TITLE TITLE TITLE TITLE TITLE TITLE TITLE TITLE TITLE TITLE  */}

            <div
              id="titleSection"
              className={`mt-auto text-[--textLight] md:text-[--textDark]`}
              // style={{ backgroundColor: "green", opacity: "1" }}
            >
              <motion.div
                key="client"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.7 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: animDelay * 1.25,
                  duration: animDuration,
                  ease: animEase,
                }}
                className="cst-clientHeaderText md:pb-[--spacer_base_globalHalf]"
                // style={{ backgroundColor: "green", opacity: "1" }}
              >
                {props.client}
              </motion.div>
              <motion.div
                className="cst-projectTitle"
                key="name"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: animDelay - animDelay,
                  duration: animDuration,
                  ease: animEase,
                }}
                // style={{ backgroundColor: "red" }}
              >
                {props.name}
              </motion.div>
            </div>
          </motion.section>

          {/* BOT SECTION */}

          <section
            id="botForeground"
            ref={inputElement}
            className={`ml-auto flex h-max w-full flex-wrap content-start px-[--spacer_base_global2] pr-[4.15vw] md:min-h-[75vh] md:content-normal md:pr-[--spacer_base_global2] md:pt-[--spacer_base_global] 
            ${props.navOn ? "md:h-[75vh]" : "md:h-max"}
            `}
            // style={{ backgroundColor: "red", opacity: "1" }}
          >
            <div
              className=" h-min w-full"
              // md:mb-[40vh]
              // style={{ backgroundColor: "black", opacity: "1" }}
            >
              {props.blurb && (
                <motion.p
                  key="blurb"
                  className="cst-blurbText pb-[--spacer_base_global] md:pb-[--spacer_base_globalHalf]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: animDelay * 1, duration: animDuration }}
                >
                  {props.blurb}
                </motion.p>
              )}

              <ul className="flex w-full flex-wrap pb-[--spacer_base_globalHalf] text-[--textLightOverlay] md:pb-[--spacer_base_global]">
                {props.mediums.map((medium, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.9 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: animDuration,
                      delay: animDelay * 1.25 + index / 5,
                      ease: animEase,
                    }}
                    className="cst-captionTextSmall mr-[--spacer_base_global] inline opacity-60"
                  >
                    {medium.name}
                  </motion.li>
                ))}
              </ul>
            </div>

            {/* DESCRIPTION DESCRIPTION DESCRIPTION DESCRIPTION DESCRIPTION DESCRIPTION DESCRIPTION DESCRIPTION   */}

            {!props.navOn && (
              <IntroDescription
                animDuration={animDuration}
                animDelay={animDelay}
                desc={props.desc}
                roles={props.roles}
                madeAt={props.madeAt}
                awards={props.awards}
                press={props.press}
              ></IntroDescription>
            )}
          </section>
        </motion.section>
      </div>
    </main>
  );
}

{
  /* <section
                className="w-full md:mt-auto"
                // style={{ backgroundColor: "red", opacity: "1" }}
              >
                <motion.section
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: animDuration,
                    delay: animDelay * 2.25,
                    ease: animEase,
                  }}
                  id="belowGrid"
                  className={`mt-[--spacer_base_global2] flex h-min w-full flex-wrap ${
                    !props.desc && "md:mb-[--spacer_base_global2]"
                  }`}
                  // style={{ backgroundColor: "red" }}
                >
                  <InfoList
                    classes={""}
                    listName="Role"
                    listItems={props.roles}
                  ></InfoList>

                  {props.madeAt && (
                    <InfoList
                      listName="Made At"
                      listItems={[props.madeAt]}
                    ></InfoList>
                  )}
                  {props.desc && <hr className="w-full"></hr>}
                </motion.section>

                {props.desc && (
                  <motion.section
                    // style={{ backgroundColor: "yellow", opacity: "1" }}
                    key="description"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: animDuration,
                      delay: animDelay * 2.25,
                      ease: animEase,
                    }}
                    className="cst-descText pt-[--spacer_base_global] md:mb-[--spacer_base_global4]"
                  >
                    {props.desc}
                  </motion.section>
                )}

                {props.awards.length > 0 || props.press.length > 0 ? (
                  <>
                    <section
                      id="belowGrid"
                      className="mb-[--spacer_base_global2] mt-[--spacer_base_global2] flex w-full justify-end  md:ml-auto"
                      // style={{ backgroundColor: "pink" }}
                    >
                      {props.press.length > 0 && (
                        <InfoListAwards
                          classes="basis-1/2 grow"
                          listName="Press"
                          listItems={props.press}
                        ></InfoListAwards>
                      )}
                      {props.awards.length > 0 && (
                        <InfoListAwards
                          classes="basis-1/2 grow"
                          listName="Awards"
                          listItems={props.awards}
                        ></InfoListAwards>
                      )}
                    </section>
                  </>
                ) : null}
              </section> */
}
