import React from "react";
import { Link } from "gatsby";

export default function IndexLink(props) {
  return (
    <Link
      className={`${props.classes} cst-captionTextSmall opacity-40 transition-all after:content-['Index'] hover:after:content-['Coming_Soon']`}
      // style={{ backgroundColor: "red" }}
    ></Link>
  );
}
