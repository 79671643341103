import React from "react";
import { Link } from "gatsby";

export default function Info_List(props) {
  return (
    <div
      className={`mr-0 flex h-min w-1/2 flex-wrap items-baseline gap-[--spacer_base_global] text-[--textLightOverlay] opacity-90`}
      // style={{ backgroundColor: "yellow" }}
    >
      <h3 className="cst-captionTextSmall ">{props.listName}</h3>
      {/* <hr></hr> */}
      {props.listItems.map((item) => (
        <p className="cst-smallText cst-noBreak">
          {item.url ? (
            <a href={item.url}>{item.name}</a>
          ) : (
            <>{item.name ? item.name : item}</>
          )}
          {/* <hr></hr> */}
        </p>
      ))}
    </div>
  );
}
