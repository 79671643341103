import React, { useState, useCallback } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import IndexLink from "../c_fragment/Index_Link";
import { AnimatePresence, motion, useMotionValue } from "framer-motion";

export default function Nav({
  selectedProject,
  setSelectedProject,
  selectedRole,
  setSelectedRole,
  navOn,
  navOverlay,
  setNavOverlay,
  isMobile,
  pageContext,
  handleProjectChange,
  handleMouseOutNav,
  projectClick,
  projectHover,
  navDuration,
  navEase,
  projectState,
}) {
  const data = useStaticQuery(graphql`
    query NewQueryName {
      allSanityProject(sort: { orderRank: ASC }) {
        nodes {
          activePage
          slug {
            current
          }
          projectName
          mediumTagArray {
            name
          }
          clientTag {
            name
          }
          roleTagArray {
            name
            altName
          }
        }
      }
      sanitySiteSettings {
        siteTitle
        navRoles {
          name
          altName
        }
        navMediums {
          name
        }
      }
    }
  `);

  const site = data.sanitySiteSettings;
  const title = site.siteTitle;
  const roles = site.navRoles;
  const allPages = data.allSanityProject.nodes;

  const pages = allPages.filter((page) => page.activePage == true);

  const [selectedMedium, setSelectedMedium] = useState(undefined);

  const handleRoleChange = useCallback(
    (event) => {
      setSelectedRole(event.target.value);
    },
    [setSelectedRole]
  );

  const handleProjectChangeExit = useCallback(() => {
    //setSelectedProject("home");
    setNavOverlay(true);
  }, [setSelectedProject]);

  const handleMediumChange = useCallback(
    (event) => {
      setSelectedMedium(event.target.value);
    },
    [setSelectedMedium]
  );

  const handleMediumChangeExit = useCallback(() => {
    setSelectedMedium(undefined);
  }, [setSelectedMedium]);

  const roleFilteredProjects = pages.filter((node) =>
    selectedRole === "All"
      ? pages
      : node.roleTagArray.some((role) => role.name === selectedRole)
  );

  function extractArray() {
    const arrayRemap = roleFilteredProjects.map((node) => node.mediumTagArray);
    const flattenArray = arrayRemap.flat();
    const mappedFlat = flattenArray.map((node) => node.name);
    const extractedMediums = [...new Set(mappedFlat)];
    return extractedMediums;
  }

  const mediums = extractArray();

  function scrollTop() {
    typeof window !== "undefined" && window.window.scrollTo(0, 0);
  }

  // const [initialAnimation, set_initialAnimation] = useState(false);

  function roleClick(event) {
    handleRoleChange(event);
    scrollTop();
  }

  var navStates =
    projectState === "mobileHomeInitial"
      ? ["100svh", "100vw"]
      : projectState === "mobileHomeRoleSelected"
      ? ["25svh", "100vw"]
      : projectState === "mobileProjectSelected"
      ? ["3svh", "100vw"]
      : projectState === "HomeInitial"
      ? ["100svh", "100vw"]
      : projectState === "HomeRoleSelected"
      ? ["100vh", "100vw"]
      : projectState === "HomeProjectHover"
      ? ["100vh", "50vw"]
      : projectState === "HomeProjectOff"
      ? ["100vh", "100vw"]
      : projectState === "ProjectNavOff"
      ? ["100vh", "0vw"]
      : projectState === "ProjectNavOn"
      ? ["100vh", "50vw"]
      : undefined;

  const animDuration = 0.5;
  const animDelay = 0.125;
  const animEase = "easeOut";
  const animY = 10;

  return (
    <AnimatePresence>
      <motion.nav
        initial={{ height: navStates[0], width: navStates[1] }}
        animate={{ height: navStates[0], width: navStates[1] }}
        exit={{ height: navStates[0], width: navStates[1] }}
        transition={{ duration: navDuration, ease: navEase }}
        onMouseLeave={
          projectState === "ProjectNavOn" ? handleMouseOutNav : null
        }
        className={`z-40 flex items-center md:fixed md:h-[screen] md:items-start
     md:items-center ${navOn && isMobile ? "sticky top-0" : "relative"} ${
          isMobile
            ? "w-screen"
            : navOn || navOverlay
            ? selectedProject !== "home"
              ? "w-[50vw]"
              : "w-screen"
            : "w-[0vw]"
        } ${navOn ? "py-[5vw]" : "py-[0vw]"}
        ${
          projectState === "mobileProjectSelected"
            ? "cst-mainGradientLR"
            : "cst-mainGradient"
        }
        `}
        // style={{ opacity: 0.25 }}
      >
        {navOn || navOverlay || isMobile ? (
          <motion.section
            initial={{ opacity: navOn ? 1 : 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: navDuration }}
            className=" z-20 flex w-full flex-wrap pl-[3.6vw] 
            pr-[6.12vw] md:mt-0 md:px-[2vw] "
            onMouseLeave={handleMediumChangeExit}
            // style={{ backgroundColor: "green" }}
          >
            {projectState == "mobileProjectSelected" && (
              <Link
                className="h-[12vh] w-full"
                to={"/"}
                value={"home"}
                // style={{ backgroundColor: "green" }}
              ></Link>
            )}
            <div
              className={`flex h-max w-full flex-wrap content-end transition-[height] 2xl:h-[50vh] ${
                projectState === "HomeInitial" ? "md:h-[50vh]" : "md:h-[40vh]"
              }`}
              // style={{ backgroundColor: "purple" }}
            >
              {(navOn && selectedRole) ||
              (!navOn && !isMobile && selectedRole) ? (
                <motion.div
                  initial={{ opacity: 0, y: animY }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    ease: animEase,
                    duration: animDuration,
                    delay: animDuration,
                  }}
                  // style={{ backgroundColor: "green" }}
                  className="mb-[--spacer_base_global] w-auto md:mb-[--spacer_base_globalHalf] md:w-full"
                >
                  <Link
                    to="/about"
                    className="cst-captionTextSmall leading-none opacity-40 transition-all hover:opacity-100"
                    value={"About"}
                    onClick={projectClick}
                    // style={{ backgroundColor: "orange" }}
                  >
                    About
                  </Link>
                </motion.div>
              ) : null}

              {(navOn && selectedRole) || (!navOn && !isMobile) ? (
                <div className="ml-auto">
                  <IndexLink
                    id="mobileLink"
                    classes={`md:hidden mt-auto  leading-nones mb-[--spacer_base_global]`}
                  ></IndexLink>
                </div>
              ) : null}

              <div
                className={`flex w-[100%] md:h-max ${
                  selectedProject === "home"
                    ? ""
                    : "md:flex-wrap xl:flex-nowrap"
                } ${isMobile && "flex-wrap"} ${
                  isMobile & (selectedProject === "home") ? "" : "h-full"
                }`}
                // style={{ backgroundColor: "orange" }}
              >
                <div
                  className="flex w-full flex-wrap md:w-[50%] xl:w-[50vw]"
                  // style={{ backgroundColor: "purple" }}
                >
                  {/* TITLE */}
                  <div
                    className=" w-full md:w-auto"
                    // style={{ backgroundColor: "red" }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    whileHover={{ opacity: 0.9 }}
                  >
                    <Link
                      to={"/"}
                      value={"home"}
                      // onClick={handleProjectChange}
                      // style={{ backgroundColor: "pink" }}
                    >
                      <p
                        key={"title"}
                        className={`
                        cst-noBreak cst-logoText
                        mb-[--spacer_base_global] mt-auto inline-block h-max w-full text-center md:mb-[0px] md:mr-[--spacer_base_global] md:w-fit md:text-left 
                        ${
                          projectState === "mobileProjectSelected"
                            ? "invisible"
                            : "visible"
                        }
                        `}
                      >
                        {title}
                      </p>
                      {/* <p
                        key={"title"}
                        className={`cst-noBreak mb-[--spacer_base_global] mt-auto inline-block h-max w-full 
                        text-center font-osBold text-[15.5vw]
                    md:mb-[0px] md:mr-[--spacer_base_global] md:w-fit md:text-left md:text-[clamp(2rem,2vw,330%)]
                        ${
                          projectState === "mobileProjectSelected"
                            ? "invisible"
                            : "visible"
                        }
                        `}
                      >
                        {title}
                      </p> */}
                    </Link>
                  </div>

                  <motion.hr
                    id="mobileHr"
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 1,
                    }}
                    className={`my-[--spacer_base_global] ${
                      projectState === "mobileHomeRoleSelected" ||
                      projectState === "mobileHomeInitial"
                        ? "block"
                        : "hidden"
                    } w-full px-[--spacer_base_global] md:hidden`}
                  ></motion.hr>

                  {/* ROLES */}
                  {navOn || (!navOn && !isMobile) ? (
                    <div
                      // style={{ backgroundColor: "blue" }}
                      className={`mb-[--spacer_base_global2] mt-auto flex h-max w-full
                 justify-between md:mb-[0px] md:w-auto md:justify-start
                  `}
                    >
                      {roles.map((node, index) => (
                        <motion.div
                          // style={{ backgroundColor: "red" }}
                          initial={{
                            opacity:
                              navOn && selectedProject === "home" ? 0 : 1,
                            y: navOn && selectedProject === "home" ? 20 : 0,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                          }}
                          transition={{
                            duration: 2,
                            delay: 0.5 + index / 3,
                            y: { duration: 1 },
                          }}
                        >
                          <button
                            // style={{ backgroundColor: "yellow" }}
                            className={`cst-captionTextSmall mt-auto min-w-max text-left uppercase leading-[1rem] transition-opacity hover:opacity-100 md:mr-[--spacer_base_global] ${
                              selectedRole === undefined
                                ? "opacity-80"
                                : selectedRole === node.name
                                ? "opacity-100"
                                : "opacity-40"
                            }`}
                            onClick={roleClick}
                            value={node.name}
                            key={index}
                          >
                            {node.altName === "Art Direction"
                              ? "Creative Direction"
                              : node.altName}
                          </button>
                        </motion.div>
                      ))}

                      <div className={`${selectedRole ? "visible" : "hidden"}`}>
                        <button
                          className={`cst-captionTextSmall mt-auto min-w-max text-left uppercase leading-[1rem] opacity-40 transition-opacity hover:opacity-100 md:mr-[--spacer_base_global] ${
                            selectedRole === "All"
                              ? "opacity-100"
                              : "opacity-40"
                          }`}
                          onClick={roleClick}
                          value={"All"}
                          // value={undefined}
                        >
                          All
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                {selectedRole && (
                  <motion.div
                    initial={{ opacity: 0, y: animY }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      ease: animEase,
                      duration: animDuration,
                      delay: animDuration,
                    }}
                    className={`mt-auto hidden min-w-max text-left md:ml-auto md:inline-block lg:ml-auto`}
                    // style={{ backgroundColor: "green" }}
                  >
                    <IndexLink id="desktopLink"></IndexLink>
                  </motion.div>
                )}
              </div>
            </div>

            {/* BREAKPOINT IS HERE */}

            {/* MEDIUMS */}
            <div
              className={`flex hidden w-screen flex-wrap content-start transition-[height] md:block 2xl:h-[50vh] ${
                projectState === "HomeInitial" ? "md:h-[50vh]" : "md:h-[60vh]"
              }`}
              // style={{ backgroundColor: "orange" }}
            >
              {/* PROJECT PAGES */}
              {!isMobile && (
                <>
                  {selectedRole && (
                    <div className="relative">
                      <motion.hr
                        initial={{ width: "0%" }}
                        animate={{ width: "100%" }}
                        transition={{
                          ease: animEase,
                          duration: animDuration,
                          delay: 0,
                        }}
                        id="desktoopHr"
                        className="border-1 invisible relative mt-[--spacer_base_globalHalf] w-80 md:visible"
                        style={{
                          background: "var(--cst-mainGradientLR)",
                          border: "1",
                          height: "1px",
                        }}
                      ></motion.hr>
                      <motion.hr
                        initial={{ width: "0%" }}
                        animate={{ width: "100%" }}
                        transition={{
                          ease: animEase,
                          duration: animDuration,
                          delay: 0,
                        }}
                        id="desktopHr"
                        className="border-1 invisible absolute left-0 top-0 w-full opacity-50 md:visible"
                        style={{
                          background: "var(--cst-mainGradientLR)",
                          border: "0",
                          height: "1px",
                        }}
                      ></motion.hr>
                    </div>
                  )}

                  <div className="w-full">
                    <div
                      className={`cst-nav_list mt-[--spacer_base_globalHalf] flex flex-wrap md:w-[calc(50vw_-_3.6vw)] `}
                    >
                      <button
                        className="cst-captionTextSmall mr-0 md:hidden"
                        onClick={handleMediumChangeExit}
                      >
                        All
                      </button>
                      {mediums.map((node, index) => (
                        <motion.div
                          initial={{ opacity: 0, y: animY * -1 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            ease: animEase,
                            duration: animDuration,
                            delay: 0 + index / 10,
                          }}
                        >
                          <motion.button
                            className={`cst-nav_listItem cst-navMediumText ${
                              selectedMedium === node
                                ? "opacity-80"
                                : "opacity-40"
                            }`}
                            onMouseEnter={handleMediumChange}
                            onClick={handleMediumChange}
                            value={node}
                            key={index}
                          >
                            {node}
                          </motion.button>
                        </motion.div>
                      ))}
                    </div>
                  </div>

                  <ul
                    // style={{ backgroundColor: "pink" }}
                    onMouseLeave={navOn ? handleProjectChangeExit : null}
                    className="cst-nav_list mt-4 md:flex md:w-[calc(50vw_-_3.6vw)] md:flex-wrap md:justify-start"
                  >
                    {roleFilteredProjects.map((node, index) => (
                      <motion.li
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          ease: animEase,
                          duration: animDuration * 2,
                          delay: animDelay * 3,
                        }}
                        className={`mr-[--spacer_base_global2] mt-0 mt-auto block `}
                        key={index}
                        // style={{ backgroundColor: "red" }}
                      >
                        <Link
                          className={`cst-projectNames transition-all hover:text-yellow-400 hover:opacity-100
                          ${
                            node.mediumTagArray.some(
                              (item) => item.name === selectedMedium
                            )
                              ? "opacity-100"
                              : "opacity-50"
                          } `}
                          key={index}
                          onMouseEnter={navOn ? projectHover : null}
                          to={`/projects/${node.slug.current}`}
                          value={node.slug.current}
                          onClick={projectClick}
                        >
                          {/* <div class="cst-navMediumText flex flex-wrap opacity-50">
                            {node.clientTag.name}:
                          </div> */}
                          {node.projectName}
                        </Link>
                      </motion.li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </motion.section>
        ) : null}
      </motion.nav>
    </AnimatePresence>
  );
}
