import React from "react";
// import { Link } from "gatsby";

export default function Info_ListAwardPress(props) {
  return (
    <div
      className={`${props.classes} h-min items-baseline text-[--textLightOverlay] opacity-90`}
      // style={{ backgroundColor: "blue" }}
    >
      <h3
        className="cst-smallText cst-captionTextSmall h-full w-min"
        // style={{ backgroundColor: "blue" }}
      >
        {props.listName}
      </h3>
      <hr className="w-full"></hr>
      <div>
        {props.listItems.map((item, index) => (
          <>
            <p
              // style={{ backgroundColor: "blue" }}
              className="cst-smallText py-[--spacer_base_globalHalf] "
            >
              {item.url ? (
                // <Link className="hover:text-[--yellow]" to={item.url}>
                //   {item.name}
                // </Link>
                <a
                  className="underline hover:text-[--yellow]"
                  target="_blank"
                  href={item.url}
                >
                  {item.name}
                </a>
              ) : (
                <>{item.name ? item.name : item}</>
              )}
            </p>
            {props.listItems.length - 1 !== index && <hr></hr>}
          </>
        ))}
      </div>
    </div>
  );
}
