import React, { useCallback, useEffect, useState } from "react";
import Header from "./a_sections/Header";
import Nav from "./a_sections/Nav";
import { motion } from "framer-motion";

export default function Layout(props) {
  const [selectedProject, setSelectedProject] = useState(
    props.pageContext.selectedProject
  );

  const [selectedRole, setSelectedRole] = useState(undefined);
  const [navOverlay, setNavOverlay] = useState(undefined);

  function useWindowSize() {
    function windowCheck(rawSize) {
      let isSmall = false;
      rawSize < 768 ? (isSmall = true) : (isSmall = false);
      return [isSmall, rawSize];
    }

    const [windowBoole, setwindowBoole] = useState(
      windowCheck(typeof window !== "undefined" && window.window.innerWidth)
    );

    useEffect(() => {
      const handleResize = () => {
        setwindowBoole(
          windowCheck(typeof window !== "undefined" && window.window.innerWidth)
        );
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return windowBoole;
  }

  const windowStatus = useWindowSize();

  const isMobile = windowStatus[0];

  const windowCheck = windowStatus[1];

  function imageSizeCheck() {
    if (windowCheck > 2400) {
      return 2400;
    } else {
      if (windowCheck > 1200) {
        return 1200;
      } else {
        return 600;
      }
    }
  }

  const windowSize = imageSizeCheck();

  const navOn = props.pageContext.navOn;

  var projectState = undefined;

  //PROJECT STATE MACHINE
  if (isMobile === true) {
    if (navOn === true) {
      if (selectedRole === undefined) {
        projectState = "mobileHomeInitial";
      }
      if (selectedRole !== undefined) {
        projectState = "mobileHomeRoleSelected";
      }
    }
    if (navOn === false) {
      projectState = "mobileProjectSelected";
    }
  }
  if (isMobile === false) {
    if (navOn === true) {
      if (selectedRole === undefined) {
        projectState = "HomeInitial";
      }
      if (selectedRole !== undefined) {
        if (selectedProject === "home") {
          projectState = "HomeRoleSelected";
        }
        if (selectedProject !== "home") {
          if (navOverlay === false || navOverlay === undefined) {
            projectState = "HomeProjectHover";
          }
          if (navOverlay === true) {
            projectState = "HomeProjectOff";
          }
        }
      }
    }

    if (navOn === false) {
      if (navOverlay === false || navOverlay === undefined) {
        projectState = "ProjectNavOff";
      }
      if (navOverlay === true) {
        projectState = "ProjectNavOn";
      }
    }
  }

  const handleNavClick = useCallback(() => {
    setNavOverlay(true);
  }, [setNavOverlay]);

  const handleProjectChange = useCallback(
    (event) => {
      setSelectedProject(event.target.attributes.value.value);
    },
    [setSelectedProject]
  );

  const handleMouseOutNav = useCallback(() => {
    setNavOverlay(false);
  }, [setNavOverlay]);

  function projectClick(event) {
    handleProjectChange(event);
    handleMouseOutNav();
  }

  const handleMouseOverProject = useCallback(() => {
    setNavOverlay(false);
  }, [setNavOverlay]);

  function projectHover(event) {
    handleProjectChange(event);
    handleMouseOverProject();
  }

  // const navHoverState = {
  //   width: isMobile
  //     ? "var(--spacer_base_global2)"
  //     : "var(--spacer_base_global2)",
  // };

  const navDuration = 0.25;
  const navEase = "easeInOut";

  const [navHoverState, setnavHoverState] = useState(false);

  const handleNavBarHover = useCallback(() => {
    setnavHoverState((navHoverState) => !navHoverState);
  }, [setnavHoverState]);

  let navWidth = "var(--spacer_base_global2)";

  if (navHoverState == true) {
    navWidth = "var(--spacer_base_global4)";
  }

  if (navHoverState == false) {
    navWidth = "var(--spacer_base_global2)";
  }

  if (isMobile == true) {
    navWidth = "0px";
  }

  // navHoverState = true;

  return (
    <main>
      {/* <Debugger
        info={[
          {
            name: "ProjectState",
            value: projectState,
          },
          {
            name: "Overlay",
            value: navOverlay,
          },
          {
            name: "SM",
            value: isMobile,
          },
          {
            name: "Role",
            value: selectedRole,
          },
          {
            name: "Project",
            value: selectedProject,
          },
        ]}
      /> */}

      <div
        onMouseEnter={handleNavBarHover}
        onMouseLeave={handleNavBarHover}
        className="fixed z-40 hidden h-[--spacer_base_global2] w-screen md:visible md:block md:h-screen md:w-[--spacer_base_global4] md:cursor-pointer"
        // style={{ backgroundColor: "red" }}
      >
        <motion.div
          // initial={navHoverState}
          // whileHover={{ width: navWidth }}
          style={{ width: navWidth }}
          // exit={navHoverState}
          onClick={handleNavClick}
          className={`cst-mainGradient flex content-center items-center justify-center transition-[width] md:h-screen md:w-[--spacer_base_global2] md:cursor-pointer `}
        >
          {/* <p
            className="opacity70 pb-[--spacer_base_global] font-osBold text-xs tracking-widest opacity-50"
            style={{
              writingMode: "vertical-rl",
              textOrientation: "sideways",
              // backgroundColor: "red",
            }}
          >
            |||
          </p> */}
        </motion.div>
      </div>

      <Nav
        projectState={projectState}
        windowSize={windowSize}
        navEase={navEase}
        navDuration={navDuration}
        isMobile={isMobile}
        navOn={navOn}
        navOverlay={navOverlay}
        setNavOverlay={setNavOverlay}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        handleProjectChange={handleProjectChange}
        projectClick={projectClick}
        projectHover={projectHover}
        handleMouseOutNav={handleMouseOutNav}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      ></Nav>
      <section
        className={`ml-0 md:ml-[--spacer_base_global2] 
        ${isMobile && props.pageContext.navOn ? "pt-0" : "pt-0"}
        `}
      >
        {props.pageContext.header && (
          <Header
            projectState={projectState}
            navEase={navEase}
            navDuration={navDuration}
            navOn={props.pageContext.navOn}
            selectedProject={selectedProject}
            windowSize={windowSize}
            isMobile={isMobile}
            selectedRole={selectedRole}
            handleProjectChange={handleProjectChange}
            projectClick={projectClick}
            handleMouseOutNav={handleMouseOutNav}
          ></Header>
        )}
        {React.cloneElement(props.children, {
          handleNavBarHover: handleNavBarHover,
          navHoverState: navHoverState,
        })}
        {/* {props.children} */}
      </section>
    </main>
  );
}
