import React, { useEffect } from "react";
import InfoListAwards from "../c_fragment/Info_List_AwardPress";
import InfoList from "../c_fragment/Info_List";
import { motion } from "framer-motion";

export default function IntroDescription(props) {
  return (
    <section
      className="w-full md:mt-auto md:pt-[40vh]"
      //   style={{ backgroundColor: "red", opacity: "1" }}
    >
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: props.animDuration,
          delay: props.animDelay * 2.25,
          ease: props.animEase,
        }}
        id="belowGrid"
        className={`mt-[--spacer_base_global2] flex h-min w-full flex-wrap ${
          !props.desc && "md:mb-[--spacer_base_global2]"
        }`}
        // style={{ backgroundColor: "red" }}
      >
        <InfoList
          classes={""}
          listName="Role"
          listItems={props.roles}
        ></InfoList>

        {props.madeAt && (
          <InfoList listName="Made At" listItems={[props.madeAt]}></InfoList>
        )}
        {props.desc && <hr className="w-full"></hr>}
      </motion.section>

      {props.desc && (
        <motion.section
          // style={{ backgroundColor: "yellow", opacity: "1" }}
          key="description"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: props.animDuration,
            delay: props.animDelay * 2.25,
            ease: props.animEase,
          }}
          className="cst-descText pt-[--spacer_base_global] md:mb-[--spacer_base_global4]"
        >
          {props.desc}
        </motion.section>
      )}

      {props.awards.length > 0 || props.press.length > 0 ? (
        <>
          <section
            id="belowGrid"
            className="mb-[--spacer_base_global2] mt-[--spacer_base_global2] flex w-full justify-end  md:ml-auto"
            // style={{ backgroundColor: "pink" }}
          >
            {props.press.length > 0 && (
              <InfoListAwards
                classes="basis-1/2 grow"
                listName="Press"
                listItems={props.press}
              ></InfoListAwards>
            )}
            {props.awards.length > 0 && (
              <InfoListAwards
                classes="basis-1/2 grow"
                listName="Awards"
                listItems={props.awards}
              ></InfoListAwards>
            )}
          </section>
        </>
      ) : null}
    </section>
  );
}
